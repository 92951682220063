import { config } from "../config";

// /** Signature of a logging function */
// export interface LogFn {
//   (message?: any, ...optionalParams: any[]): void;
// }

// /** Basic logger interface */
// export interface Logger {
//   log: LogFn;
//   warn: LogFn;
//   error: LogFn;
// }

/** Log levels */
// export type LogLevel = 'log' | 'warn' | 'error';

const NO_OP = (message, ...optionalParams) => {};

/** Logger which outputs to the browser console */
class Logger {
  log;
  warn;
  error;

  constructor(options) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === "error") {
      this.warn = NO_OP;
      this.log = NO_OP;

      return;
    }

    this.warn = console.warn.bind(console);

    if (level === "warn") {
      this.log = NO_OP;

      return;
    }

    this.log = console.log.bind(console);
  }
}

export const logger = new Logger({ level: config.logLevel });
