import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";

export default function ProtectedRoute({
  isAllowed,
  redirectPath = "/login",
  children,
}) {
  // const isAuthenticated = localStorage.getItem("authenticated") === "true";
  // console.log("this", isAuthenticated);

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
}
