import React, { useEffect, useState, useRef, useContext } from "react";
import { Navigate } from "react-router-dom";
import { config } from "../config";
import { fetchUtil } from "../utils/fetchUtil";
import { request } from "../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { logger } from "../utils/logger";
import Header from "../components/header";
import UserContext from "../context/UserContext";

const Dashboard = () => {
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  const { setAuthenticated } = useContext(UserContext);

  // assume authenticated
  const { isLoading, error, data } = useQuery({
    queryKey: ["loginQuery"],
    queryFn: async () => {
      const res = await fetchUtil.fetchWrapper(config.apis.profileUri, "GET");
      //TODO: ADD error handling for 500
      if (res.status === 401) {
        // move to fetchwrapper
        logger.log("req - unauthenticated -> redirecting");
        setAuthenticated(false);
        // localStorage.setItem("authenticated", false);
        navigate("/login");
      }
      logger.log("req ok");
      setUser(res.body);
    },
  });

  if (isLoading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    logger.log("An error has occurred: " + error.message);
    <div>
      <p>{"An error has occurred: " + error.message}</p>
    </div>;
  }

  if (user) {
    const token = fetchUtil.getDecodedToken();
    return (
      <>
        <Header username={token.username} />
        <div>
          <p>Welcome to your Dashboard</p>
          <pre>{JSON.stringify(token, null, 2)}</pre>
          <pre>{JSON.stringify(user.data, null, 2)}</pre>
        </div>
      </>
    );
  }
};

export default Dashboard;
