import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Input from "../components/Inputs";
import { useNavigate } from "react-router-dom";
import { config } from "../config";
import { fetchUtil } from "../utils/fetchUtil";
import Spinner from "../components/Spinner";
import { logger } from "../utils/logger";

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.5rem 0;

  .login-container {
    width: 500px;
  }

  .login-header {
    text-align: center;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 16px;
  }

  button {
    background-color: #04aa6d;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
  }

  button:hover {
    opacity: 0.8;
  }

  .cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
  }

  .imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
  }

  img.avatar {
    width: 40%;
    border-radius: 50%;
  }

  .container {
    padding: 16px;
  }

  span.psw {
    float: right;
    padding-top: 16px;
  }

  /* Change styles for span and cancel button on extra small screens */
  @media only screen and (max-width: 700px) {
    /* width: 10px; */

    .login-container {
      width: 280px;
    }

    /* span.psw {
      display: block;
      float: none;
    }
    .cancelbtn {
      width: 100%;
    } */
  }
`;

// const navigate = useNavigate();

export default function Login({ setAuthAndStorage, authenticated }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();

  // TODO: add back later, only so on mobile can get to logi page
  //
  useEffect(() => {
    console.log("from login: " + authenticated);
    if (authenticated) {
      navigate("/");
    }
  }, [authenticated, navigate]);

  const doLogin = async (event) => {
    logger.log("logging in");
    event.preventDefault();
    setIsSubmitted(true);
    const result = await fetchUtil.login(username, password);
    // logger.log(result);
    if (result.data.success) {
      setAuthAndStorage(true);
      navigate("/");
    } else {
      setIsSubmitted(false);
      setErrorMessage({
        type: "validation",
        message: result.data && result.data.message,
      });
    }
  };

  return (
    <StyledDiv>
      <div className="login-container">
        <h2 className="login-header">Project-B Login</h2>
        <div className="imgcontainer">
          <img src="img_avatar2.png" alt="Avatar" className="avatar" />
        </div>

        {/* <Spinner size="50" /> */}

        {isSubmitted && <Spinner size="50" />}

        {!isSubmitted && (
          <form action="#" onSubmit={doLogin}>
            <div className="form-input-container">
              <div className="header">
                <label className="label-header">Username</label>
              </div>
              <div className="display">
                <input
                  type="text"
                  className="login-label"
                  placeholder="Username"
                  onChange={(x) => setUsername(x.target.value)}
                  value={username}
                  inputMode="text"
                  required={true}
                  name="username"
                  autoComplete="on"
                />
              </div>
            </div>
            <div className="form-input-container">
              <div className="header">
                <label className="label-header">Password</label>
              </div>
              <div className="display">
                <input
                  type="password"
                  className="login-label"
                  placeholder="Password"
                  onChange={(x) => setPassword(x.target.value)}
                  value={password}
                  required={true}
                  name="password"
                  autoComplete="on"
                />
              </div>
            </div>

            {errorMessage.message && (
              <div className="login-errors">{errorMessage.message}</div>
            )}

            <button type="submit">Login</button>
          </form>
        )}
      </div>
    </StyledDiv>
  );
}
