import styled from "styled-components";
import React from "react";

const Style = styled.div`
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: ${(props) => props.size + "px"};
    height: ${(props) => props.size + "px"};
    animation: spin 2s linear infinite;
  }

  .loader-small {
    border: 10px solid #f3f3f3;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 10px solid #168db1;
    border-radius: 50%;
    width: ${(props) => props.size + "px"};
    height: ${(props) => props.size + "px"};
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function Spinner(props) {
  return (
    <Style size={props.size}>
      {/* <div class="loader"></div> */}
      <div className="loader-small"></div>
    </Style>
  );
}
