import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  useContext,
} from "react";
import styled from "styled-components";
import { fetchUtil } from "../utils/fetchUtil";
import { config } from "../config";
import { logger } from "../utils/logger";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";

const Styles = styled.div`
  a,
  li.subnav {
    text-decoration: none;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  /* Header */
  .header {
    background-color: var(--black);
    box-shadow: 1px 1px 5px 0px var(--grey);
    position: sticky;
    top: 0;
    width: 100%;
  }

  /* Logo */
  .logo {
    display: inline-block;
    color: var(--white);
    font-size: 60px;
    margin-left: 10px;
  }

  /* Nav menu */
  .nav {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: var(--black);
    overflow: hidden;
  }

  .menu a,
  .menu p {
    display: block;
    padding: 30px;
    color: var(--white);
  }

  .menu a:hover {
    background-color: var(--grey);
  }

  .nav {
    max-height: 0;
    transition: max-height 0.5s ease-out;
  }

  /* Sub nav */
  .subnav-content {
    background-color: var(--white);
    width: 100%;
    z-index: 1;
    padding: 20px 0;
    display: none;
  }
  .subnav-content a {
    color: var(--black);
    text-decoration: none;
    padding: 0;
    margin: 10px 0;
    text-align: center;
  }
  .subnav:hover .subnav-content {
    display: block;
  }

  /* Menu Icon */

  .hamb {
    cursor: pointer;
    float: right;
    padding: 40px 20px;
  }

  .hamb-line {
    background: var(--white);
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
  }

  .hamb-line::before,
  .hamb-line::after {
    background: var(--white);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .hamb-line::before {
    top: 5px;
  }

  .hamb-line::after {
    top: -5px;
  }

  .side-menu {
    display: none;
  }

  /* Toggle menu icon */

  .side-menu:checked ~ nav {
    max-height: 100%;
  }

  .side-menu:checked ~ .hamb .hamb-line {
    background: transparent;
  }

  .side-menu:checked ~ .hamb .hamb-line::before {
    transform: rotate(-45deg);
    top: 0;
  }

  .side-menu:checked ~ .hamb .hamb-line::after {
    transform: rotate(45deg);
    top: 0;
  }

  /* Responsiveness */

  @media (min-width: 768px) {
    .nav {
      max-height: none;
      top: 0;
      position: relative;
      float: right;
      width: fit-content;
      background-color: transparent;
    }

    .menu li {
      float: left;
    }

    .menu a:hover {
      background-color: transparent;
      color: var(--grey);
    }

    .hamb {
      display: none;
    }

    /* Sub nav */
    .subnav-content {
      padding: 20px 0;
      display: none;
      background-color: var(--black);
    }
    .subnav-content a {
      color: white;
    }
  }
`;

export default function Header({ username }) {
  const navigate = useNavigate();

  const { setAuthenticated } = useContext(UserContext);

  const doLogout = (evt) => {
    fetchUtil
      .fetchWrapper(config.apis.logoutUri, "GET")
      .then((x) => {
        if (x.status === 401) {
          logger.log("req - unauthenticated -> logging out anyway");
          setAuthenticated(false);
          navigate("/login");
        } else {
          logger.log("req - ok -> logged out");
          setAuthenticated(false);
          navigate("/login");
        }
      })
      .catch((x) => {
        logger.log("HTTP Error");
        logger.log(x);
      });
  };

  return (
    <Styles>
      <header className="header">
        <a href="#" className="logo">
          {username.substring(0, 1).toUpperCase()}
        </a>
        <input className="side-menu" type="checkbox" id="side-menu" />
        <label className="hamb" htmlFor="side-menu">
          <span className="hamb-line"></span>
        </label>
        <nav className="nav">
          <ul className="menu">
            <li>
              <a href="#">Gallery</a>
            </li>
            <li>
              <a href="#">Blog</a>{" "}
            </li>
            <li>
              <a href="#">About</a>
            </li>
            <li className="subnav">
              <p className="subnavbtn">
                Contact <i className="fa fa-caret-down"></i>
              </p>
              <div className="subnav-content">
                <a href="#">Email</a>
                <a href="#">Twitter</a>
                <a href="#">Phone</a>
              </div>
            </li>
            <li>
              <a onClick={doLogout}>Logout</a>
            </li>
          </ul>
        </nav>
      </header>
    </Styles>
  );
}
