import { config } from "../config";
import { logger } from "./logger";
import jwt from "jwt-decode";

export const fetchUtil = (() => {
  const fetch = window.fetch;
  let accessToken;

  const resToJson = async (res) => {
    const isJson = res.headers.get("content-type").includes("application/json");
    return isJson ? await res.json() : null;
  };

  const refresh = async () => {
    logger.log("doing refresh");
    const res = await fetch(config.apis.refreshUri, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    });
    const data = await resToJson(res);
    return {
      status: res.status,
      body: data,
    };
    // if (!res.ok) {
    //   console.log("refresh error");
    //   const error = (data && data.message) || res.status;
    //   return Promise.reject(error);
    // }
    // console.log(" refresh ok");
    //
    // return await Promise.resolve("SUCCESS");
  };

  const fetchApi = async (uri, method, body) => {
    const options = {
      method: method,
      mode: "cors",
      credentials: "include",
      headers: {
        authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    if (body) {
      options.body = JSON.stringify(body);
    }

    return fetch(uri, options)
      .then(async (res) => {
        const data = await resToJson(res);
        // console.log(res);
        return Promise.resolve({
          status: res.status,
          body: data,
        });
      })
      .catch((x) => logger.log(x));
  };

  const fetchWrapper = async (uri, method, body) => {
    const firstResponse = await fetchApi(uri, method, body);

    if (firstResponse.status < 400) {
      logger.log("access token ok");
      return firstResponse;
    } else {
      logger.log("catch -> will try refresh");
      let refreshResult = await refresh();
      // console.log(JSON.stringify(refreshResult, null, 2));
      if (refreshResult.status === 200) {
        logger.log("refresh ok");
        accessToken = refreshResult.body.data.accessToken;
        let secondResponse = await fetchApi(uri, method, body);
        return secondResponse;
      } else {
        logger.log("can't refresh");
        return firstResponse;
      }
    }
    // catch error fetching original request
  };

  // .catch(async (res) => {
  //   console.log("catch -> will try refresh");
  //   let refreshResult = await refresh();
  //   let result = fetchApi(uri, method, body);
  //   return Promise.resolve(result);
  // });

  return {
    getDecodedToken: () => jwt(accessToken),
    fetchWrapper,
    login: async (username, password) => {
      let res = await fetch(config.apis.loginUri, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      const isJson = res.headers
        .get("content-type")
        .includes("application/json");
      const json = isJson ? await res.json() : {};
      if (res.ok && json?.data?.success) {
        accessToken = json.data.accessToken;
      }
      return {
        status: res.status,
        data: json.data || {},
      };
    },
  };
})();
