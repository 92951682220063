export const config = (() => {
  let apiServer = "https://api.lukerennison.com";
  let logLevel = "warn";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    apiServer = "";
    logLevel = "log";
  } else {
    // production code
  }
  return {
    apis: {
      loginUri: apiServer + "/v1/auth/login",
      logoutUri: apiServer + "/v1/auth/logout",
      profileUri: apiServer + "/v1/users/profile",
      refreshUri: apiServer + "/v1/auth/refresh",
    },
    logLevel,
  };
})();
