import { config } from "../config";

export const login = (username, password) => {
  return fetch(config.loginUri, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });
};

export const request = (uri, method, body) => {
  const options = {
    method: method,
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (body) {
    options.body = JSON.stringify(body);
  }

  // check 403 => body.errorType === "TOKEN_EXPIRED"
  return fetch(uri, options);
};
