import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// const append = (data) => {
//   textarea.append(`${new Date()} --> ${data} \n`);
//   textarea.scrollTop(textarea[0].scrollHeight);
// };

const refreshToken = () => {
  // inactiveButtons();
  // fetch(`${BACKEND_URL}/api/refresh`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({ username: "elie29" }),
  // })
  //   .then(handleError)
  //   .then((next) => {
  //     append(`${next.username}, token refreshed`);
  //     toggleButtons(true);
  //   })
  //   .catch((message) => clearAll(`${message} on refresh`));
};

// let intervalId = 0;
// const BACKEND_URL = "http://localhost:3000";
// const REFRESH_TIME_MS = 4 * 60 * 1000;

// navigator.serviceWorker
//   .register("/sw.js", {
//     scope: "/",
//   })
//   .then(() => navigator.serviceWorker.ready)
//   .then(() => console.log("ServiceWorker registration successful!"))
//   .then(() => refreshToken())
//   .then(() => (intervalId = setInterval(refreshToken, REFRESH_TIME_MS)))
//   .catch((err) => console.log(`ServiceWorker registration failed: ${err}`));
