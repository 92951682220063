import React, { useState, useEffect, useRef, Suspense } from "react";
import styled from "styled-components";

const Styles = styled.div``;

export default function Input(props) {
  return (
    <Styles height={props.height}>
      <div className="holder">
        <div className="header">
          <label className="label-header">{props.label}</label>
        </div>
        <div className="display">
          <input
            type={props.type}
            className="label-value"
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
            inputMode="text"
          />
        </div>
      </div>
    </Styles>
  );
}
