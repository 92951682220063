import React, { useEffect, useState, useRef, createContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoute from "./components/ProtectedRoute";
import { config } from "./config";
import { fetchUtil } from "./utils/fetchUtil";
import { request } from "./utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { logger } from "./utils/logger";
import UserContext from "./context/UserContext";

const queryClient = new QueryClient();

function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") == "true"
  );

  const setAuthAndStorage = (x) => {
    console.log("updating auth storage");
    localStorage.setItem("authenticated", x);
    setAuthenticated(x);
  };

  useEffect(() => {
    console.log("from app: " + authenticated);
  }, [authenticated]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider
        value={{ authenticated, setAuthenticated: setAuthAndStorage }}
      >
        <Router>
          <div>
            <Routes>
              <Route
                path="/login"
                element={
                  <Login
                    authenticated={authenticated}
                    setAuthAndStorage={setAuthAndStorage}
                  />
                }
              />
              {/* <Route path="/about" element={<About />} />
            <Route path="/users" element={<Users />} /> */}
              <Route element={<ProtectedRoute isAllowed={authenticated} />}>
                <Route path="/" element={<Home />} />
              </Route>
            </Routes>
          </div>
        </Router>
      </UserContext.Provider>
    </QueryClientProvider>
  );
}

// function Home() {
//   return <h2>Home</h2>;
// }

// function About() {
//   return <h2>About</h2>;
// }

// function Users() {
//   return <h2>Users</h2>;
// }

export default App;
